import { useEffect, useRef, useState } from 'react';

const useTabShadow = () => {
  const [showShadow, setShowShadow] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (tabListRef.current) {
      const { scrollWidth, scrollLeft, clientWidth } = tabListRef.current;
      //https://stackoverflow.com/a/34550171/7033335
      setReachedEnd(Math.abs(scrollWidth - scrollLeft - clientWidth) <= 3.0);
    }
  };

  useEffect(() => {
    const tabList = tabListRef.current;
    const wrapper = wrapperRef.current;

    if (tabList && wrapper) {
      const myObserver = new ResizeObserver((entries) => {
        entries.forEach(() => {
          if (tabList?.scrollWidth > wrapper?.scrollWidth) {
            setShowShadow(true);
          } else {
            setShowShadow(false);
          }
          onScroll();
        });
      });
      myObserver.observe(tabList);

      return () => {
        if (myObserver) {
          myObserver.unobserve(tabList);
        }
      };
    }
  }, []);

  return {
    tabListRef,
    wrapperRef,
    showShadow,
    reachedEnd,
    onScroll,
  };
};

export default useTabShadow;
