import {
  ReactElement,
  Ref,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import {
  BaseSelect,
  Container,
  DropdownLabel,
  GlobalDropdownStyles,
} from './Dropdown.styles';
import Error from '../Error';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import type { Props as ReactSelectProps } from 'react-select';
import { HelperTextProp } from '../Label/Label';

type List<T> = {
  label: string | ReactElement;
  value: T;
  isDisabled?: boolean;
};

export type BaseDropdownProps<T> = {
  error?: string;
  placeholder?: string | ReactElement;
  compact?: boolean;
  options: List<T>[];
  onChange: (value: T) => void;
  className?: string;
  label?: string | ReactElement;
  helperText?: HelperTextProp;
  disabled?: boolean;
  value?: T;
  'data-testid'?: string;
} & Omit<ReactSelectProps, 'onChange' | 'value'>;

function Dropdown<T>(
  {
    compact,
    options,
    onChange,
    onFocus,
    onBlur,
    className,
    disabled,
    error,
    placeholder,
    value,
    label,
    styles,
    helperText,
    ...props
  }: BaseDropdownProps<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>,
) {
  const { 'data-testid': testId } = props;
  const activeElement = options.find((v) => v.value === value);
  const id = useId();
  const [focused, setFocused] = useState(false);
  const documentBody = useRef<HTMLElement>();

  useEffect(() => {
    documentBody.current = document.body;
  }, []);

  const handleFocus: ReactSelectProps['onFocus'] = (e) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur: ReactSelectProps['onBlur'] = (e) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Container
      className={className}
      data-testid={testId}
      $error={!!error}
      $focused={focused}
    >
      <GlobalDropdownStyles />
      {label && (
        <DropdownLabel htmlFor={id} error={!!error} helperText={helperText}>
          {label}
        </DropdownLabel>
      )}
      <BaseSelect
        inputId={id}
        // https://github.com/JedWatson/react-select/issues/5459
        instanceId={id}
        placeholder={placeholder}
        classNamePrefix={'Wreno_Dropdown'}
        $error={error}
        $compact={compact}
        menuPortalTarget={documentBody.current}
        // can be removed after we activate strict mode
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(option: any) => {
          onChange(option.value as T);
        }}
        ref={ref}
        {...props}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          ...styles,
        }}
        components={{
          IndicatorsContainer: () => (
            <ChevronDownIcon className="Wreno_Dropdown--arrow" />
          ),
        }}
        isDisabled={disabled}
        value={activeElement ?? null}
        options={options}
        isSearchable={options.length > 3}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {error && <Error errorMessage={error} inline />}
    </Container>
  );
}

// Cast the type for the ref to HTMLElement
export default forwardRef(Dropdown) as <T>(
  p: BaseDropdownProps<T> & { ref?: Ref<unknown> },
) => ReactElement;
