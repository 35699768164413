import styled from 'styled-components';
import Button from '../Button';
import { CTASmall } from '../Typography/Typography.styles';

export const TabListShadowWrapper = styled.div`
  position: relative;
  display: grid;
  overflow: hidden;
`;

export const TabButtonItem = styled(Button)<{ $totalButtons?: number }>`
  padding: 0;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  & svg {
    height: 24px;
    width: 24px;
    padding: 3px;
    box-sizing: border-box;
  }

  & .Wreno_Button--label {
    margin: 0;
    display: ${({ $totalButtons }) =>
      $totalButtons && $totalButtons > 1 ? 'none' : 'flex'};

    @media ${({ theme }) => theme.breakpoints.large} {
      display: flex;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 26px;
  white-space: nowrap;
`;

export const Wrapper = styled.div<{ $isSecondaryVariant?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  gap: 18px;

  ${({ $isSecondaryVariant, theme }) =>
    $isSecondaryVariant &&
    `
    background: ${theme.colors.gray100};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}

  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 12px 32px;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

export const TabsShadow = styled.div<{ $isSecondaryVariant?: boolean }>`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(
    270deg,
    ${({ theme, $isSecondaryVariant }) =>
        $isSecondaryVariant ? theme.colors.gray100 : theme.colors.white}
      0%,
    rgba(230, 237, 237, 0) 26px
  );
`;

export const TabListWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 27px;
`;

type TabProps = {
  $isActive?: boolean;
};

export const TabWrapper = styled.button<TabProps>`
  height: auto;
  text-wrap: nowrap;
  flex-shrink: 0;
  padding: 6px 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  ${CTASmall};
  color: ${({ theme }) => theme.colors.black};
  transition: padding 0.3s;
  ${({ $isActive }) => $isActive && `padding: 6px 12px;`}
  ${({ $isActive, theme }) => $isActive && `background: ${theme.colors.black};`}
  border-radius: 50px;
  :not(:focus-visible) {
    outline: none;
  }
  &:focus-visible {
    outline-offset: 4px;
  }
  &[aria-selected='true'] {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const TabPanels = styled.div<{ $isSecondaryVariant?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $isSecondaryVariant, theme }) =>
    $isSecondaryVariant &&
    `
  background: ${theme.colors.gray50};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`}
`;

export const TabPanel = styled.div<{
  $paddingX?: boolean;
  $paddingY?: boolean;
  $active?: boolean;
}>`
  ${({ $active, $paddingX, $paddingY, theme }) =>
    $active &&
    Boolean($paddingX || $paddingY) &&
    `
    padding: ${$paddingY ? '16px' : '0'} ${$paddingX ? '16px' : '0'};

    @media ${theme.breakpoints.large} {
      padding: ${$paddingY ? '24px' : '0'} ${$paddingX ? '32px' : '0'};
    }
  `}
`;

export const TabLabel = styled.span<{ $isActive?: boolean }>`
  z-index: 8;
  transition: color 0.4s;

  ${({ theme, $isActive }) =>
    $isActive &&
    `
    color: ${theme.colors.white};
  `}
`;
