import React, { SVGAttributes } from 'react';

export default function ChevronDownIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={18}
      height={11}
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 2l7 7 7-7"
        stroke="currentColor"
        strokeWidth={2.48889}
        strokeLinecap="round"
      />
    </svg>
  );
}
