import styled, { createGlobalStyle, css } from 'styled-components';
import Select from 'react-select';
import Label from '../Label';
import { BodyStandard, LabelStandard } from '../Typography/Typography.styles';
import type { DefaultTheme } from 'styled-components';

export type ListBoxInputProps = {
  $error?: string;
  $compact?: boolean;
};

export const DropdownLabel = styled(Label)``;

export const Container = styled.div<{ $focused?: boolean; $error?: boolean }>``;

export const baseSelectStyles = ({
  theme,
  $compact,
  $error,
}: { theme: DefaultTheme } & ListBoxInputProps) => css`
  .Wreno_Dropdown__control {
    position: relative;
    width: 100%;
    border: 1px solid ${theme.colors.gray400};
    min-height: 48px;
    padding: 4px 15px;
    font-family: ${theme.fontFamily.main};
    ${BodyStandard};
    color: ${theme.colors.black};
    background: ${theme.colors.white};
    border-radius: 4px;
    box-sizing: border-box;
    &--menu-is-open {
      box-shadow: none;
      border: 1px solid ${theme.colors.black};
      .Wreno_Dropdown--arrow {
        transform: rotate(180deg);
      }
    }
    &.Wreno_Dropdown__control--is-focused {
      border: 1px solid ${theme.colors.black};
      box-shadow: none;
    }
    .Wreno_Dropdown__value-container {
      margin-right: 6px;
    }
    .Wreno_Dropdown__value-container--is-multi {
      gap: 5px;
    }
    .Wreno_Dropdown__input-container {
      margin: 0;
      padding: 0;
    }
    .Wreno_Dropdown__multi-value {
      margin: 0;
      background: ${theme.colors.wrenoYellowPrimary};
      border-radius: 6px;
    }
    .Wreno_Dropdown__multi-value__label {
      padding: 0 2px 0 6px;
      margin: 0;
    }
    .Wreno_Dropdown--arrow {
      transition: transform 200ms;
      color: ${theme.colors.black};
    }
    ${$error &&
    css`
      border-color: ${theme.colors.alertRed} !important;
    `}
  }
  .Wreno_Dropdown--loader {
    height: 20px;
    width: 20px;
    padding: 0;
    margin-right: 10px;
  }
  ${$compact &&
  css`
    .Wreno_Dropdown__control {
      padding: 0 8px;
      min-height: 34px;
      ${LabelStandard};
    }
    .Wreno_Dropdown--arrow {
      height: 8px;
    }
    .Wreno_Dropdown--loader {
      height: 10px;
      width: 10px;
      padding: 0;
      margin-right: 10px;
    }
  `}
  .Wreno_Dropdown__value-container {
    padding: 0;
  }
  .Wreno_Dropdown__indicator-separator {
    display: none;
  }
`;

export const BaseSelect = styled(Select)<ListBoxInputProps>`
  ${({ theme, $error, $compact }) =>
    baseSelectStyles({ theme, $error, $compact })}
`;

export const GlobalDropdownStyles = createGlobalStyle`
  .Wreno_Dropdown--is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  div.Wreno_Dropdown__menu {
    ${({ theme }) => css`
      background: ${theme.colors.white};
      box-shadow: 0 15px 30px rgba(0,0,0,.3) !important;
      border: none;
      border-radius: 4px;
      margin-top: 4px;
      padding: 12px 0;
      max-height: 240px;
      overflow: auto;
      box-sizing: border-box;
      position: relative;
      z-index: 11;
      box-sizing: border-box;
      max-height: 100%;
      overflow-y: auto;
      .Wreno_Dropdown__group-heading {
        margin-bottom: 8px;
      }
      & .Wreno_Dropdown__option {
        padding: 0;
        ${BodyStandard};
        transition: all 0.2s ease;
        cursor: pointer;
        border-radius: 0;
        padding: 4px 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: auto;
        &--is-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &--is-selected,
        &--is-focused {
          background: ${(props) => props.theme.colors.wrenoYellowLightShade};
          color: ${(props) => props.theme.colors.black};
        }
        &--is-selected {
          font-weight: 500;
            background: ${(props) =>
              props.theme.colors.wrenoYellowPrimary} !important;
          }
        }
      }
    `}
  }
`;
