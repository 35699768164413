import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import {
  Wrapper,
  TabsWrapper,
  TabWrapper,
  TabListWrapper,
  TabPanels,
  TabPanel,
  TabLabel,
  TabsShadow,
  ButtonWrapper,
  TabButtonItem,
  TabListShadowWrapper,
} from './Tabs.styles';
import { LayoutGroup } from 'framer-motion';
import useTabShadow from './hooks/useTabShadow';
import { ButtonProps } from '../Button';

export type TabList = {
  label: string | ReactElement;
  render?: string | ReactElement;
  paddingX?: boolean;
  paddingY?: boolean;
};

export type TabButton = {
  icon: ReactNode;
  onClick: () => void;
  label: string | ReactElement;
};

export type Props = {
  tabLists: TabList[];
  onChange?: (index: number) => void;
  defaultTabIndex?: number;
  className?: string;
  isControlled?: boolean;
  buttons?: ButtonProps[];
  variant?: 'primary' | 'secondary';
};

interface TabContext {
  setButtonOverrides?: (buttonOverrides: ButtonProps[]) => void;
  currentTab: TabList;
  selectedTabIndex: number;
}

const Context = createContext<TabContext>({
  currentTab: { label: '' },
  selectedTabIndex: 0,
});

const Tabs = ({
  tabLists,
  onChange,
  defaultTabIndex = 0,
  className,
  isControlled,
  buttons: _buttons,
  variant,
}: Props) => {
  const [buttonOverrides, setButtonOverrides] = useState<ButtonProps[]>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);
  const tabId = useId();
  const isSecondaryVariant = variant === 'secondary';
  const activeTabPillRef = useRef<HTMLButtonElement>(null);
  const { tabListRef, wrapperRef, showShadow, reachedEnd, onScroll } =
    useTabShadow();

  const buttons = buttonOverrides || _buttons;
  const totalButtons = buttons?.length || 0;

  useEffect(() => {
    setSelectedTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  useEffect(() => {
    if (selectedTabIndex >= tabLists.length) {
      setSelectedTabIndex(0);
    }
  }, [selectedTabIndex, tabLists.length]);

  const handleTabSelect = (index: number) => {
    if (index === selectedTabIndex) {
      return;
    }
    if (!isControlled) {
      setSelectedTabIndex(index);
    }
    if (onChange) {
      onChange(index);
    }
    setButtonOverrides(undefined);
  };

  useEffect(() => {
    if (activeTabPillRef.current && showShadow) {
      activeTabPillRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [selectedTabIndex, showShadow]);

  return (
    <TabsWrapper className={className}>
      <Wrapper
        className="tabs--wrapper"
        $isSecondaryVariant={isSecondaryVariant}
      >
        <TabListShadowWrapper ref={wrapperRef}>
          <LayoutGroup>
            <TabListWrapper
              className="tabs--list-wrapper"
              ref={tabListRef}
              onScroll={onScroll}
            >
              {tabLists.map((tab, i) => {
                const isActive = selectedTabIndex === i;
                return (
                  <TabWrapper
                    className="tabs--tab"
                    key={i}
                    $isActive={isActive}
                    tabIndex={0}
                    onClick={() => handleTabSelect(i)}
                    aria-controls={`${tabId}--tabs--panels--${i}`}
                    aria-selected={isActive}
                    ref={isActive ? activeTabPillRef : null}
                    role="tab"
                    id={`${tabId}--tabs--tab--${i}`}
                  >
                    <TabLabel $isActive={isActive}>{tab.label}</TabLabel>
                  </TabWrapper>
                );
              })}
            </TabListWrapper>
          </LayoutGroup>
          {showShadow && !reachedEnd ? (
            <TabsShadow
              className="tabs--scroll-shadow"
              $isSecondaryVariant={isSecondaryVariant}
            />
          ) : null}
        </TabListShadowWrapper>
        {buttons?.length ? (
          <ButtonWrapper>
            {buttons?.map((buttonProps, idx) => (
              <TabButtonItem
                key={`${tabId}-buttons-${buttonProps.label}-${idx}`}
                variant="tertiary"
                iconPosition="left"
                {...buttonProps}
                $totalButtons={totalButtons}
              />
            ))}
          </ButtonWrapper>
        ) : null}
      </Wrapper>
      <TabPanels
        $isSecondaryVariant={isSecondaryVariant}
        className="tabs--panel-wrapper"
      >
        {tabLists.map(
          (tab, i) =>
            tab.render && (
              <TabPanel
                tabIndex={-1}
                key={i}
                id={`${tabId}--tabs--panels--${i}`}
                aria-labelledby={`${tabId ? `${tabId}--` : ''}tabs--tab--${i}`}
                role="tabpanel"
                className="tabs--panel"
                $paddingX={tab.paddingX}
                $paddingY={tab.paddingY}
                $active={selectedTabIndex === i}
              >
                {selectedTabIndex === i && (
                  <Context.Provider
                    value={{
                      setButtonOverrides,
                      currentTab: tabLists[selectedTabIndex],
                      selectedTabIndex,
                    }}
                  >
                    {tab.render}
                  </Context.Provider>
                )}
              </TabPanel>
            ),
        )}
      </TabPanels>
    </TabsWrapper>
  );
};

export function useTabContext() {
  const context = useContext(Context);
  return context;
}

export default Tabs;
