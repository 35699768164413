import styled from 'styled-components';
import Button from 'styleguide/src/components/Button';
import Typography from 'styleguide/src/components/Typography';
import Modal from 'styleguide/src/components/Modal';
import Dropdown from 'styleguide/src/components/Dropdown';
import Tabs from 'styleguide/src/components/Tabs';

// User Management Style
export const HeadWrapper = styled.div`
  margin-left: 20px;
  margin-right: 10px;
`;

export const HeadTool = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled(Typography)`
  margin-bottom: 16px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  margin-top: 10px;
`;

export const TableJobRowGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const TableJobRowButton = styled(Button)<{
  $multipleChildren?: boolean;
}>`
  width: 80px;
  height: 32px;
  background: none;
  border: ${(props) => `1px solid ${props.theme.colors.machineGreen600}`};
  color: ${(props) => props.theme.colors.machineGreen600};
  padding: 0px;
  margin-left: 8px;
  display: flex;
  justify-content: center;

  ${({ $multipleChildren }) =>
    $multipleChildren &&
    `&:first-child {
    width: 32px;
    margin-left: 0px;
    & > div {
      margin: 0px;
    }
  }`}
`;

// User Modal
export const UserModalContainer = styled(Modal)`
  position: relative;
  max-width: 898px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};
  padding: 60px 135px 70px;
`;

export const Divider = styled.div<{ marginTop?: number }>`
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '60px')};
  height: 1px;
  background: ${(props) => props.theme.colors.black};
  opacity: 0.1;
  width: 100%;
`;

export const UserInputForm = styled.form`
  margin: 40px 0;
`;

export const UserInput = styled.div`
  margin-bottom: 30px;
`;

export const UserNameInput = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 20px;
`;

export const UserRoleTab = styled(Tabs)`
  margin-bottom: 40px;
`;

export const UserFilterDropdown = styled(Dropdown)`
  max-width: 210px;
  width: 100%;
  flex: 1;
  margin-right: 1rem;
`;

export const UserInputFooter = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  border-top: 1px solid ${(props) => props.theme.colors.gray400};
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const AddUserButton = styled(Button)`
  background: none;
  color: ${(props) => props.theme.colors.black};
  cursor: initial;
  svg {
    margin-right: 8px;
  }
`;

export const DiscardButton = styled(Button)`
  margin-right: 12px;
`;

export const RemoveUserButton = styled(Button)`
  color: ${(props) => props.theme.colors.black};
`;

export const CreateProfileButton = styled(Button)``;

export const ErrorWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const PhoneInputWrapper = styled.div`
  width: calc(50% - 10px);
`;

export const ModalTitle = styled(Typography)`
  margin-bottom: 13px;
`;

export const UserDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 559px;
`;

export const UserDeleteTitle = styled(Typography)`
  margin-top: 60px;
`;

export const UserDeleteDesc = styled(Typography)`
  margin-top: 13px;
  color: ${(props) => props.theme.colors.gray800};
`;

export const ServiceItemWrapper = styled.div`
  margin-bottom: 6px;
  display: inline-block;
`;

export const ServiceItem = styled.span`
  display: inline-block;
  background: ${(props) => props.theme.colors.gray50};
  padding: 6px 12px;
  margin-right: 6px;
  border-radius: 6px;
`;

export const ServiceCell = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
